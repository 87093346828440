export default function useSuccessToaster() {
  const toaster = useToaster()

  // TODO: figure out why this doesn't work
  const { t } = useI18n({
    messages: {
      en: { success: 'Success!' },
      nl: { success: 'Gelukt!' },
    },
  })

  const toast = ({ message, title }: { message: string, title?: string }) => {
    toaster.clearAll()

    toaster.show({
      title: title || t('success'),
      message: message,
      color: 'success',
      icon: 'ph:check',
      closable: true,
    })
  }

  return { toast }
}
